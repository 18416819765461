module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Juse Portfolio","short_name":"Juse Portfolio","start_url":"/","background_color":"#2a8266","theme_color":"#2a8266","display":"browser","icon":"src/images/favicon.png","icons":[{"src":"/images/favicon.png","sizes":"196x196","type":"image/png","purpose":"any maskable"},{"src":"/images/favicon.png","sizes":"512x512","type":"image/png","purpose":"any maskable"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"24bee3c9a55ad65ac88a3a1f3b4817a0"},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
